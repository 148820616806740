// actionTypes.js

export const FETCH_TABLE_ORDERS_REQUEST = 'FETCH_TABLE_ORDERS_REQUEST';
export const FETCH_TABLE_ORDERS_SUCCESS = 'FETCH_TABLE_ORDERS_SUCCESS';
export const FETCH_TABLE_ORDERS_FAILURE = 'FETCH_TABLE_ORDERS_FAILURE';

export const FETCH_TABLE_ORDER_BY_ID_REQUEST = 'FETCH_TABLE_ORDER_BY_ID_REQUEST';
export const FETCH_TABLE_ORDER_BY_ID_SUCCESS = 'FETCH_TABLE_ORDER_BY_ID_SUCCESS';
export const FETCH_TABLE_ORDER_BY_ID_FAILURE = 'FETCH_TABLE_ORDER_BY_ID_FAILURE';

export const CREATE_TABLE_ORDER_REQUEST = 'CREATE_TABLE_ORDER_REQUEST';
export const CREATE_TABLE_ORDER_SUCCESS = 'CREATE_TABLE_ORDER_SUCCESS';
export const CREATE_TABLE_ORDER_FAILURE = 'CREATE_TABLE_ORDER_FAILURE';

export const UPDATE_TABLE_ORDER_REQUEST = 'UPDATE_TABLE_ORDER_REQUEST';
export const UPDATE_TABLE_ORDER_SUCCESS = 'UPDATE_TABLE_ORDER_SUCCESS';
export const UPDATE_TABLE_ORDER_FAILURE = 'UPDATE_TABLE_ORDER_FAILURE';

export const DELETE_TABLE_ORDER_REQUEST = 'DELETE_TABLE_ORDER_REQUEST';
export const DELETE_TABLE_ORDER_SUCCESS = 'DELETE_TABLE_ORDER_SUCCESS';
export const DELETE_TABLE_ORDER_FAILURE = 'DELETE_TABLE_ORDER_FAILURE';

export const FETCH_TABLE_ORDERS_BY_USER_ID_REQUEST = 'FETCH_TABLE_ORDERS_BY_USER_ID_REQUEST';
export const FETCH_TABLE_ORDERS_BY_USER_ID_SUCCESS = 'FETCH_TABLE_ORDERS_BY_USER_ID_SUCCESS';
export const FETCH_TABLE_ORDERS_BY_USER_ID_FAILURE = 'FETCH_TABLE_ORDERS_BY_USER_ID_FAILURE';

export const FETCH_TABLE_ORDERS_BY_RESTAURANT_ID_REQUEST = 'FETCH_TABLE_ORDERS_BY_RESTAURANT_ID_REQUEST';
export const FETCH_TABLE_ORDERS_BY_RESTAURANT_ID_SUCCESS = 'FETCH_TABLE_ORDERS_BY_RESTAURANT_ID_SUCCESS';
export const FETCH_TABLE_ORDERS_BY_RESTAURANT_ID_FAILURE = 'FETCH_TABLE_ORDERS_BY_RESTAURANT_ID_FAILURE';

export const UPDATE_ORDER_STATUS_REQUEST = "UPDATE_ORDER_STATUS_REQUEST";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAILURE = "UPDATE_ORDER_STATUS_FAILURE";
