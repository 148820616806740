export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";

export const GET_USERS_ORDERS_REQUEST = "GET_USERS_ORDERS_REQUEST";
export const GET_USERS_ORDERS_SUCCESS = "GET_USERS_ORDERS_SUCCESS";
export const GET_USERS_ORDERS_FAILURE = "GET_USERS_ORDERS_FAILURE";

export const GET_USERS_NOTIFICATION_REQUEST = "GET_USERS_NOTIFICATION_REQUEST";
export const GET_USERS_NOTIFICATION_SUCCESS = "GET_USERS_NOTIFICATION_SUCCESS";
export const GET_USERS_NOTIFICATION_FAILURE = "GET_USERS_NOTIFICATION_FAILURE";

export const UPDATE_ORDER_STATUS_REQUEST = "UPDATE_ORDER_STATUS_REQUEST";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAILURE = "UPDATE_ORDER_STATUS_FAILURE";