export const GET_ALL_EVENT_REQUEST = "GET_ALL_EVENT_REQUEST";
export const GET_ALL_EVENT_SUCCESS = "GET_ALL_EVENT_SUCCESS";
export const GET_ALL_EVENT_FAILURE = "GET_ALL_EVENT_FAILURE";

export const GET_ALL_RESTAURANT_EVENT_REQUEST = "GET_ALL_RESTAURANT_EVENT_REQUEST";
export const GET_ALL_RESTAURANT_EVENT_SUCCESS = "GET_ALL_RESTAURANT_EVENT_SUCCESS";
export const GET_ALL_RESTAURANT_EVENT_FAILURE = "GET_ALL_RESTAURANT_EVENT_FAILURE";

export const GET_EVENT_BY_ID_REQUEST = "GET_EVENT_BY_ID_REQUEST";
export const GET_EVENT_BY_ID_SUCCESS = "GET_EVENT_BY_ID_SUCCESS";
export const GET_EVENT_BY_ID_FAILURE = "GET_EVENT_BY_ID_FAILURE";

export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE";

export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";

export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";