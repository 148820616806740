export const GET_INGREDIENTS = "GET_INGREDIENTS";
export const UPDATE_STOCK = "UPDATE_STOCK";

export const CREATE_INFREDIENT_REQUEST = "CREATE_INFREDIENT_REQUEST";
export const CREATE_INFREDIENT_SUCCESS = "CREATE_INFREDIENT_SUCCESS";
export const CREATE_INFREDIENT_FAILURE = "CREATE_INFREDIENT_FAILURE";

export const CREATE_INGREDIENT_CATEGORY_REQUEST = "CREATE_INGREDIENT_CATEGORY_REQUEST";
export const CREATE_INGREDIENT_CATEGORY_SUCCESS = "CREATE_INGREDIENT_CATEGORY_SUCCESS";
export const CREATE_INGREDIENT_CATEGORY_FAILURE = "CREATE_INGREDIENT_CATEGORY_FAILURE";

export const GET_INGREDIENT_CATEGORY_REQUEST = "GET_INGREDIENT_CATEGORY_REQUEST";
export const GET_INGREDIENT_CATEGORY_SUCCESS = "GET_INGREDIENT_CATEGORY_SUCCESS";
export const GET_INGREDIENT_CATEGORY_FAILURE = "GET_INGREDIENT_CATEGORY_FAILURE";