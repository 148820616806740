import React from 'react'
import { TableOrderTable } from './TableOrderTable'

export const TableOrders = () => {
  return (
    <div className='px-2'>
      <TableOrderTable/>
    </div>
  )
}
