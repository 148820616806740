import React from 'react'
import { FoodCategoryTable } from './FoodCategoryTable'

export const FoodCategory = () => {
  return (
    <div>
      <FoodCategoryTable/>
    </div>
  )
}
