import { Card, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import { OrderTable } from './OrderTable'
const orderStatus = [
  {label:"Chuẩn bị", value:"PENDING"},
  {label:"Hoàn thành", value:"COMPLETED"},
  {label:"Tất cả", value:"ALL"},
]
export const Orders = () => {
  
  const [filterValue, setFilterValue] = useState()
  const handleFilter = (e, value) => {
    setFilterValue(value)
  }
  return (
    <div className='px-2'>
      {/* <Card className='p-5'>
        <Typography sx={{ paddingBottom: "1rem" }} variant='h5'>Trạng thái đơn hàng</Typography>
        <FormControl>
          <RadioGroup onChange={handleFilter} row name='category' value={ filterValue || "ALL"}>
            {orderStatus.map((item) => <FormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio/>}
            label={item.label}
            sx={{color:"gray"}}
            />)}
          </RadioGroup>
        </FormControl>
      </Card> */}
      <OrderTable/>
    </div>
  )
}
